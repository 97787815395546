import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { useAuth } from 'src/utils/auth';
import { useState, useEffect } from 'react'
import SuspenseLoader from 'src/components/common/SuspenseLoader';
import { AuthMiddleware } from 'src/middlewares/AuthMiddleware';

interface SpifexLayoutProps {
    children?: ReactNode;
}

const SpifexLayout: FC<SpifexLayoutProps> = () => {    
    const { handleInitUser } = useAuth();

    const [loadingAuth, setLoadingAuth] = useState(true)

    useEffect(() => {
        const authenticateUser = async () => {
            await handleInitUser();
            setLoadingAuth(false)
        }

        authenticateUser();
    }, [])

    if (loadingAuth) {
        return (
            <SuspenseLoader noLoadNp />
        )
    } 

    return (
        <AuthMiddleware>
            <div
                className='spifex-layout__container'
                style={{
                    flex: 1,
                    height: '100%',
                }}
            >
                <div
                    className='spifex-layout__content'
                    style={{
                        position: 'relative',
                        zIndex: 5,
                        display: 'block',
                        flex: 1
                    }}
                >
                    <div
                        className='spifex-layout__outlet'
                        style={{ display: 'block' }}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </AuthMiddleware>
    );
};

export default SpifexLayout;