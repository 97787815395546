import { useEffect } from 'react';
import NProgress from 'nprogress';
import './styles.css';

type Props = {
    noLoadNp?: boolean;
    noLoadProgress?: boolean;
};

const SuspenseLoader = ({ noLoadNp = false, noLoadProgress = false }: Props) => {
    useEffect(() => {
        if (!noLoadNp) {
            NProgress.start();

            return () => {
                NProgress.done();
            };
        }
    }, [noLoadNp]);

    return (
        <div
            style={{
                position: 'fixed',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            {!noLoadProgress && (
                <div className="SuspenseLoader"></div>
            )}
        </div>
    );
}

export default SuspenseLoader;
