import { useRoutes } from 'react-router-dom';
import routes from './router';

import { RequestsProvider } from './utils/RequestsContext';

export const App = () => {
  const content = useRoutes(routes);

  return (
    <RequestsProvider>
      <div>
        {content}
      </div>
    </RequestsProvider>
  );
}
