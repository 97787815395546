import React, { createContext, useContext, ReactNode } from 'react';
import { useRequests } from './requests';

const RequestsContext = createContext<any>(null);

export const RequestsProvider = ({ children }: { children: ReactNode }) => {
  const requests = useRequests();
  return (
    <RequestsContext.Provider value={requests}>
      {children}
    </RequestsContext.Provider>
  );
};

export const useRequestsContext = () => useContext(RequestsContext);
